/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

.c-button {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  text-align: center;
  /* [4] */
  margin: $inuit-global-spacing-unit-micro 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-large;
  transition: $global-transition;
  @include inuit-font-size($inuit-font-size-h4);
  text-decoration: none;
  border: $global-border solid color-var(border);
  border-radius: $global-radius;
  color: color-var(font, 3);
  position: relative;
  font-weight: 700;


  &:before {
    @include absolutePseudoContent();
    @include absPosition100();
    background: color-var(bg, 8);
    opacity: 0;
    @include transition();
    pointer-events: none;
  }
  .c-label--file:hover &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    &:before {
      opacity: 0.2;
    }
  }

  /* Style variants
   ========================================================================== */

  &--primary {
    border-color: color-var(border, 2);
    background-color: color-var(bg, 2);

    &:hover{
      color: color-var(font, 1);
      border-color: color-var(border, 3);

      background-color: color-var(bg, 3);
      @include transition();
    }
  }

  &--secondary {
    border-color: color-var(border, 3);
    background-color: color-var(bg, 3);
    color: color-var(font, 1);
  }

  &--tertiary {
    border-color: color-var(border, 4);
    background-color: color-var(bg, 4);
  }
  &--error {
    border-color: color-var(error);
    background-color: color-var(error);
  }
  &--success {
    border-color: color-var(success);
    background-color: color-var(success);
  }

  &--full {
    width: 100%;
  }

  &--fixed {
    min-width: $inuit-global-baseline * 50;
  }

  &--alternative {
    background-color: color-var(bg, 1);
    color: color-var(font);
    &:before {
      display: none;
    }

    &:hover,
    &:active,
    &:focus {
      color: color-var(font, 3);

      &.c-button--primary {
        color: color-var(bg, 2);
        .c-icon * {
          fill: color-var(bg, 2);
        }
      }

      &.c-button--secondary {
        color: color-var(bg, 3);
        .c-icon * {
          fill: color-var(bg, 3);
        }
      }

      &.c-button--tertiary {
        color: color-var(bg, 4);
        .c-icon * {
          fill: color-var(bg, 4);
        }
      }
      &.c-button--error {
        color: color-var(error);
        .c-icon * {
          fill: color-var(error);
        }
      }
      &.c-button--success {
        color: color-var(success);
        .c-icon * {
          fill: color-var(success);
        }
      }
    }
  }

  &--with-icon {
    position: relative;
    padding-left: $inuit-global-spacing-unit-huge;
    padding-right: $inuit-global-spacing-unit-huge;

    &-right {
      .c-icon {
        left: auto;
        right: $inuit-global-spacing-unit-tiny;
      }
    }

    .c-icon * {
      fill: color-var(font, 3);
    }

    &.c-button--alternative {
      .c-icon * {
        fill: color-var(font);
      }
    }
  }

  /* Size variants
   ========================================================================== */

  &--small {
    padding: 0.25rem 0.5rem;
  }

  &--large {
    padding: 0.75rem 1.5rem;
  }

  &--huge {
    padding: 0.75rem 3.5rem;
  }
}
