.c-lp-hero{
  background-color: color-var(bg, 2);
  background-repeat: no-repeat;
  background-position: left 10% top 60px;
  background-size: contain;
  padding-top: 0;

  @include mq($from: large){
    background-position: left calc((100% - 1100px) / 2) top 60px;
  }
  &__title{
    color: color-var(font, 3);
    margin-top: 130px;
    font-size: 38px;
    line-height: 60px;
    font-weight: 700;
    margin-bottom: 16px;
  }
  &__desc{
    color: color-var(font, 3);
    font-size: 24px;
    line-height: 36px;
  }
  &__image{}

  &__form{
    background-color: color-var(bg, 3);
    padding-top: 40px;
    &-heading{
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }
    &-desc{
      font-size: 16px;
      text-align: center;
    }
    &-contact{
      padding: 50px;
      margin-bottom: -140px;

      input[type=text], input[type=email], select{
        @extend .c-input;
      }

      select{
        background-image: url('../assets/images/svg/chevron.svg');
        background-repeat: no-repeat;
        background-position: center right 20px;
      }

      input[type=submit]{
       @extend .c-button;
        @extend .c-button--primary;
        @extend .c-button--huge;

        &:hover{
          background-color: darken(color-var(bg, 2), 10%) !important;
          color: color-var(font, 3);
        }
      }

      .wpcf7-list-item{
        margin-left: 0;
      }

      .comment-form-fm-sign{
        margin-bottom: 0;
        label{
          margin-bottom: 17px;
          display: inline-block;
          padding-left: 35px;
          position: relative;
          cursor: pointer;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            height: 16px;
            left: 0;
            width: 16px;
            border: 1px solid color-var(bg, 1);
            margin-right: 7px;
            vertical-align: middle;
          }
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;

          &.active{
            &:after{
              content: "L";
              transform: scaleX(-1) rotate(-45deg);
              display: block;
              position: absolute;
              left: 4.5px;
              top: -1px;
              font-size: 11px;
            }
          }
        }
      }


      input[type=checkbox]{
        display: none;
        & + .wpcf7-list-item-label{
          margin-bottom: 17px;
          display: inline-block;
          padding-left: 35px;
          position: relative;
          cursor: pointer;
          &:before{
            content: "";
            position: absolute;
            display: inline-block;
            height: 24px;
            left: 0;
            width: 24px;
            border: 1px solid #000;
            background-color: #fff;
            margin-right: 7px;
            vertical-align: middle;
          }
          font-size: 13px;
          line-height: 18px;
          font-weight: 500;
          a{
            color: #000;
          }
        }

        &:checked + .wpcf7-list-item-label{
          &:after{
            content: "L";
            transform: scaleX(-1) rotate(-45deg);
            display: block;
            position: absolute;
            left: 5.5px;
            top: 2px;
            font-size: 20px;
            font-weight: 700;
          }
        }
      }

      .complete{
        display:none;
      }

      .more{
        font-size: 13px;
        line-height: 18px;
        cursor:pointer;
      }




    }
  }
}
