.c-newsletter {
  text-align: center;
  &__fields {
    text-align: left;
    //margin: $inuit-global-spacing-unit-huge;
  }
  &__desc {
    font-size: 14px;
  }

  .c-label--toggleable-arr {
    font-size: 14px;
  }

  &-footer {
    background-color: color-var(bg, 5);
    .c-newsletter__title {
      br {
        display: none;
      }
    }
    .c-newsletter__desc {
      br {
        display: none;
      }
    }
    [type=submit] {
      @include mq($until: large) {
        padding: 12px 64px;
      }
    }
    .c-label--inline{
      @include mq($from: large){
        display: inline-block;
        width: 25%;
      }
    }
    .c-label--toggleable{
      border: none;
      @include mq($from: large){
        width: 50%;
        margin: 0 auto;
      }
    }
  }
}
