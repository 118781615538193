/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0;
}

.c-main-nav__item {
  color: color-var(font, 4);
  font-weight: 700;
  text-transform: uppercase;
  list-style: none;
  font-size: 16px;
  line-height: 24px;
  margin: 0;


  &:not(:first-of-type){
    margin-left: $inuit-global-spacing-unit-large;
  }


  &:hover .c-main-nav__dropdown {
    display: flex;
  }

  &.menu-item-has-children {
    position: relative;
  }
}

.c-main-nav__link {
  display: block;
  padding: $inuit-global-spacing-unit-big 0;
  text-decoration: none;
  @include transition();
  &:hover {
    color: color-var(font, 5);
    @include transition();
  }
}

.current-menu-item .c-main-nav__link {
  color: color-var(font, 4);
}

.c-main-nav__dropdown {
  display: none;
  flex-direction: column;
  margin: 0;
  position: absolute;
  z-index: 1000;
  top: 100%;
  left: 0;
  background: color-var(bg, 1);
  padding: $inuit-global-spacing-unit-large 0 0;
  box-sizing: 0 16px 32px rgba(0, 0, 0, 0.15);

  &:before {
    @include absolutePseudoContent();
    bottom: 100%;
    left: 0;
    width: 100%;
    height: $inuit-global-spacing-unit-large;
  }

  .c-main-nav__list {
    padding: 0;
  }
}

.c-main-nav__list,
.c-main-nav__subitem {
  list-style: none;
  margin: 0;
}

.c-main-nav__sublink {
  display: block;
  padding: $inuit-global-spacing-unit-small $inuit-global-spacing-unit-big;
  text-decoration: none;
  color: color-var(font);

  &:hover {
    color: color-var(font, 4);
  }
}

.current-menu-item .c-main-nav__sublink {
  border-bottom-color: color-var(border);
}
