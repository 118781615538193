/* ==========================================================================
   #LINK
   ========================================================================== */

.o-link {
  color: inherit;
  //text-decoration: underline;
  @include transition();

  &:hover {
    color: color-var(font, 4);
    @include transition();
  }
  &--visible {
    text-decoration: none;
    color: color-var(font, 4);
    &:hover {
      text-decoration: underline;
    }
  }


  &--second {
    color: color-var(font, 5);
    text-decoration: none;
    font-size: $inuit-global-font-size;
    line-height: $inuit-global-line-height;

    &:hover {
      color: darken(color-var(font, 5), 15%);
    }
  }

  &--bold {
    font-weight: 700;
  }

  &--pointer {
    cursor: pointer;
  }

}
