.c-post-list {
  &__title {
    margin-bottom: 0;
  }
  &__content {
    margin-top: $inuit-global-spacing-unit-huge;
  }
  &__cta {
    margin-top: $inuit-global-spacing-unit-huge;
  }
  &--reversed {
    margin-top: -$inuit-global-spacing-unit-huge * 6;
  }
  &--bordered {
    .c-tease,
    .c-article {
      box-shadow: $global-shadow;
      background: color-var(bg);
      color: color-var(font) !important;
      &.c-tease,
      &.c-article,
      &.c-tease *,
      &.c-article * {
        color: color-var(font) !important;
      }
      .c-tag {
        color: color-var(font, 3) !important;

        &--alternative {
          color: color-var(font, 3) !important;
        }
      }
    }
  }
  &__item {
    margin-top: $inuit-global-spacing-unit-great;
    .c-article {
      height: 100%;
    }
  }

  &__label-select{
    margin-bottom: 20px;
    @extend .u-font-color--4;
    text-transform: unset;
    font-weight: 700;
    @include inuit-font-size($inuit-global-font-size);
  }

  .c-select__title{
    background-color: color-var(bg, 5);
    border: none;
  }
  .c-label--select{
    margin-bottom: 0;
  }

  &__sidebar{
    @include mq($until: large){
      margin-top: 30px;
    }
  }


}
