.c-user-panel{
  padding: 0;

  &__heading{
    font-weight: 400;
  }

  &__sidebar{
    position: relative;
    margin-top: -69px;
    padding-top: 40px;

    &:after{
      content: "";
      height: 100%;
      background-color: color-var(bg, 8);
      display: block;
      position: absolute;
      z-index: -2;
      width: 100vw;
      left: 0;
      top: 0;
    }
  }

  &__input{
    .c-label__title{
      @extend .u-text--strong;
      @extend .u-font-color--4;
      margin-bottom: 10px;
    }
  }

}
