.wpml-ls-last-item {
  margin-left: 5px !important;
}

.wpml-ls-last-item a:before {
  content: '/';
  font-size: 10px;
  vertical-align: top;
  padding-top: 3px;
  display: inline-block;
  margin-right: 5px !important;
  color: #fff !important;
}

@media (max-width: 768px) {
  .wpml-ls-menu-item {
    display: inline-block;
    padding-left: 0;
    padding-right: 0;
  }

  .c-main-nav {
    text-align: center;
  }

  .wpml-ls-last-item a:before {
    color: #000 !important;
  }
}

.wpml-ls-menu-item a:after {
  display: none !important;
}

.wpml-ls-current-language a,
.wpml-ls-menu-item a:hover {
  color: #6deed8 !important;
}

.c-score__logo {
  top: 10px;
  right: 20px;
}

.c-score__desc {
  min-height: 5em;
}

.u-recapchta-info {
  padding: 15px 0;
  max-width: 300px;
  font-size: 0.6em !important;
  opacity: 0.7;
}

.grecaptcha-badge {
  visibility: hidden;
}

.u-post-ingredients p{
  margin-bottom: 0;
}

html, body {
  font-display: swap;
}