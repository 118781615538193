.c-register {
  background-color: color-var(bg,2);
  color: color-var(font,3);
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  padding: 17px 0;
  a {
    color: color-var(font, 5);
  }
}
