/* ==========================================================================
   #POST
   Includes styles for default WP images classes
   ========================================================================== */

.c-post-item {
  &__sidebar {
    position: relative;

    padding-top: 40px;

    @include mq($from: large){
      margin-top: -69px;
    }

    &:after{
      content: "";
      height: 100%;
      background-color: color-var(bg, 8);
      display: block;
      position: absolute;
      z-index: -2;
      width: 100vw;
      left: 0;
      top: 0;
    }

    .c-input--search {
      background-color: color-var(bg, 1);
    }
  }
}

.c-post {
  @include clearfix();
  margin-bottom: 2em;

  &__author {
    &-name {
      margin-bottom: 0;
    }

    &-img {
      width: $inuit-global-spacing-unit * 4;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: $inuit-global-spacing-unit-tiny;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__date {
    color: color-var(font, 2);
  }

  &__header{
    position: relative;
    height: 520px;
    padding-bottom: 48px;
    &-image{
      height: 100%;
      width: 1000px;
      position: absolute;
      right: 0;
      background-size: cover;
      background-position: center;
      &:before{
        content: "";
        height: 100%;
        width: 100%;
        background-color: color-var(bg, 9);
        opacity: 0.25;
        left: 0;
        top: 0;
        position: absolute;
      }
    }
    &-title{
      width: 380px;
      max-width: 100%;
      color: color-var(font, 3);
      position: absolute;
      bottom: 50px;
      margin-bottom: 0;
    }
  }

  &__excerpt{
    padding: $inuit-global-spacing-unit-great 0;
    font-size: 24px;
    line-height: 36px;
    color: color-var(font,9);
    //float: right;
    position: relative;
    width: 100%;

    &-content{
      max-width: 670px;
    }

    &:after{
      content: "";
      background-color: color-var(bg, 5);
      float: right;
      width: 1000px;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      z-index: -2;
    }
  }

  &__footer{
    width: 770px;
    max-width: 100%;
    padding-bottom: 40px;

    &-line{
      margin-top: 30px;

    }
  }

  table{
    tr{
      td{
        padding: 10px;
      }
    }
  }

  &__comments-form{
    label{
      display: block;
    }
    textarea{
      width: 100%;
      height: 50px;
      resize: none;
      @extend .c-input;
    }

    input[type=text]{
      @extend .c-input;
      width: 50%;
    }

    input[type=submit]{
      @extend .c-button;
      @extend  .c-button--primary;
    }

    .comment-form-cookies-consent, .comment-form-url{
      display: none;
    }

  }
  &__comments{}
  &__comment{
    &-author{
      //background-color: color-var(bg,2);
      //color: color-var(font, 3);
      display: inline-block;
      padding: 10px;
    }
    &-text{
      background-color: color-var(bg, 5);
      padding: 15px;
      p{
        &:last-of-type{
          margin-bottom: 0;
        }
      }
    }
  }

  &__banner{
    height: 620px;
    width: 100%;
    display: block;
    background-size: cover;
    background-position: center;

    @include mq($from: medium){
      height: 100%;
    }
  }


}

.c-post__content {
  max-width: 670px;
  margin-top: $inuit-global-spacing-unit-great;
  &--full-width {
    max-width: 100%;
  }
  @extend .wp-block;
}

.alignnone {
  margin: 1em 1em 1em 0;
}

.aligncenter {
  margin: 1em auto;
}

.alignright {
  float: right;
  margin: 1em 0 1em 1em;
}

.alignleft {
  float: left;
  margin: 1em 1em 1em 0;
}

.wp-caption {
  text-align: center;
}

.wp-caption-text {
  font-style: italic;
  margin-top: 0.5em;
}
