.c-badge{
  background-color: color-var(bg, 6);
  color: color-var(font, 3);
  text-transform: lowercase;
  font-size: 12px;
  border-radius: 12px;
  padding: 3px 11px;
  line-height: 18px;
  display: inline-block;
}
