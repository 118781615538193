/* ==========================================================================
   #tease
   ========================================================================== */

.c-tease {
  $self: &;
  display: block;
  position: relative;
  text-decoration: none !important;
  @include inuit-font-size($inuit-font-size-h5);
  height: 100%;

  .o-link {
    text-decoration: none !important;
  }

  &:hover {
    color: inherit;
  }

  &__title {
    text-align: center;
    margin-bottom: $inuit-global-spacing-unit;
  }

  &__desc {
    padding: $inuit-global-spacing-unit-great $inuit-global-spacing-unit-large;
  }

  &__content {
    @include inuit-font-size($inuit-font-size-h4);
  }

  &__excerpt {
    margin-bottom: 0;
  }

  &__image {
    background-size: cover;
    background-position: center center;
    position: relative;
    display: block;

    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 59.26%;
    }

    &-elem {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &--horizontal,
  &--big {
    display: flex;
  }

  &--horizontal {
    #{$self}__desc,
    #{$self}__image {
      flex: 1;
    }
    #{$self}__image {
      max-width: 270px;
    }
    #{$self}__desc {
      padding: 0;
      padding-left: $inuit-global-spacing-unit;
    }
  }

  &--big {
    #{$self}__desc {
      flex: 7;
      padding: $inuit-global-spacing-unit;
      @include inuit-font-size($inuit-font-size-h4);
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;

      &:after {
        content: '';
        flex: 0 0 100%;
      }
    }

    #{$self}__meta,
    #{$self}__content {
      flex: 0 0 100%;
    }

    #{$self}__image {
      flex: 5;
      order: 2;

      &:before {
        padding-bottom: 100%;
      }
    }
  }

  &--promo {
    text-align: center;

    #{$self}__meta {
      justify-content: center;
    }
  }

  &--simple {
    background: transparent;
    @include inuit-font-size($inuit-font-size-h4);
    padding: $inuit-global-spacing-unit-tiny 0;
    border-bottom: $global-border solid color-var(border);

    #{$self}__title {
      margin-bottom: 0;
    }
    &:hover {
      #{$self}__title {
        color: color-var(font, 4);
      }
    }
  }
  &--team {
    padding: $inuit-global-spacing-unit-huge 0;
  }
  &--team .c-tease {
    &__image {
      width: 230px;
      max-width: 100%;
      margin: auto;
      border-radius: 50%;
      &:before {
        padding-bottom: 100%;
      }
    }

    &__desc {
      text-align: center;
    }
    &__additional-info {
      margin-top: $inuit-global-spacing-unit-big;
    }

    &__phone,
    &__email {
      display: block;
      text-align: center;
    }
  }

  &--post-sidebar{
    border-bottom: 1px solid color-var(border, 7);
    padding: 15px 0;
    .c-tease{
      &__title{
        font-size: 14px;
        line-height: 21px;
        color: color-var(font, 1);
        height: 42px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__link{
        font-size: 14px;
        line-height: 21px;
        color: color-var(font, 4);
        font-weight: 700;
      }
    }
  }

  &--product{

    @include mq($until: large){
      margin-bottom: 30px;
    }

    .c-tease{
      &__top{
        padding: 50px 16px 15px 16px;
        text-align: center;
        background-image: url('../assets/images/svg/ks_shape.svg');

        background-position: -90px -170px;
        background-size: 130%;
        background-repeat: no-repeat;
        &-image{
          height: 270px;
          display: block;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
        }
        &-title{
          color: color-var(font,3);
        }
        &-desc{
          color: color-var(font,3);
          min-height: 140px;
          font-size: 12px;
          font-weight: 700;
        }
      }
      &__bottom{
        min-height: 280px;
        text-align: center;
        padding: 30px 15px 30px 15px;
        &-text{
          margin-top: 30px;
          color: color-var(font,3);
          font-size: 12px;
          line-height: 16px;
        }
      }
    }


    &.c-tease--style-1{
      .c-tease__top{
        background-color: color-var(bg,2);
      }
      .c-tease__bottom{
        background-color: darken(color-var(bg,2), 5%);
      }
    }
    &.c-tease--style-2{
      .c-tease__top{
        background-color: color-var(bg,11);
      }
      .c-tease__bottom{
        background-color: color-var(bg,3);
      }
    }
    &.c-tease--style-3{
      .c-tease__top{
        background-color: color-var(bg,12);
      }
      .c-tease__bottom{
        background-color: color-var(bg,9);
      }
    }
    &.c-tease--style-4{
      .c-tease__top{
        background-color: color-var(bg,3);
      }
      .c-tease__bottom{
        background-color: color-var(bg,11);
      }
    }



  }
}
