.c-lp-icons {
  background-color: color-var(bg, 13);
  &__bg-max{
    position: relative;
  }
  &__content{
    z-index: 100;
    position: relative;
    &--first{
      padding-top: 85px;
    }
  }
  &__background{
    position: absolute;
    height: calc(100% - 60px);
    width: calc(100% + 117px + 117px);
    top: 0;
    left: -117px;
    z-index: 0;
    &:after{
      content: "";
      background-color: color-var(bg, 9);
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.4;
    }
  }
  &__title{
    color: color-var(font, 3);
  }
  &__desc{
    color: color-var(font, 3);
  }
  &__logo{}
  &__boxes{
    margin-top: 260px;
  }
  &__box{
    text-align: center;
    background-color: color-var(bg, 6);
    height: 100%;

    &-top{
      background-color: color-var(bg, 1);
      padding: 32px 50px 24px 50px;
    }

    &-circle{
      background-color: color-var(bg, 3);
      height: 80px;
      width: 80px;
      display: inline-block;
      border-radius: 50%;
      font-size: 36px;
      line-height: 80px;
      text-align: center;
      color: color-var(font, 4);
      font-weight: 700;
      margin-bottom: 32px;
    }
    &-title{
      margin-bottom: 0;
      color: color-var(font, 4);
    }
    &-desc{
      color: color-var(font, 3);
      font-size: 12px;
      line-height: 16px;
      padding: 24px 16px;
      font-weight: 700;

      p:last-of-type{
        margin-bottom: 0;
      }
    }
  }

  &__bottom{
    margin-top: 40px;
    text-align: center;

    h1,h2,h3,h4,h5,h6{
      color: color-var(font, 4);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 28px;
    }
    p{
      font-size: 12px;
      line-height: 21px;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
  }
}
