.c-seo-links {
  background-color: color_var(bg, 2);

  &__header {
    @include inuit-font-size($inuit-font-size-h4);
    color: color-var(font, 5);
    font-weight: bold;
    text-transform: uppercase;
  }

  &__list {
    color: color-var(font, 3);
    margin: 0;
    list-style: none;
  }

  &__link {
    @include inuit-font-size($inuit-font-size-h4);

    &:hover, &:focus {
      color: color-var(font, 5);
    }
  }

  &__item {
    @include mq($until: medium) {
      width: 100%;
      margin-bottom: $inuit-global-spacing-unit-large;
      text-align: center;
    }
  }

  &--space {
    @include mq($from: medium) {
      margin-left: $inuit-global-spacing-unit-massive + $inuit-global-spacing-unit-big;
    }
  }
}
