.c-pagination{
  text-align: center;
  width: 100%;
  margin-top: 40px;
  &__pages {
    display: inline-block;
    list-style: none;
    margin: 0 20px;

  }
  &__item {
    display: inline-block;
    margin: 2px;

    .o-link{
      color: color-var(font, 2);
      font-size: 16px;
      line-height: 19px;
      font-weight: 400;
    }
    .current{
      color: color-var(font, 4);
      font-size: 16px;
      line-height: 19px;
      font-weight: 700;
    }
  }
  &__prev-btn{
    position: relative;
    vertical-align: sub;
    height: 20px;
    width: 20px;
    display: inline-block;
    transform: rotate(90deg);
    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: $inuit-global-spacing-unit-large;
      background: url('../assets/images/svg/chevron.svg') no-repeat center right;

    }
  }

  &__next-btn{
    position: relative;
    vertical-align: middle;
    height: 20px;
    width: 20px;
    display: inline-block;
    transform: rotate(-90deg);

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: $inuit-global-spacing-unit-large;
      background: url('../assets/images/svg/chevron.svg') no-repeat center right;
    }
  }
}
