.c-banner {
  &--image {

    background-repeat: no-repeat;
    background-position: center;
    background-size: 1400px;
    position: relative;
    padding: 0;
    img{
      width: 100%;
      height: auto;
      @include mq($from: medium){
       display: none;
      }
    }

    @include mq($from: medium){
      height: 420px;
      padding: 40px 0;
    }

    .c-button {
      color: color-var(font, 1);
      margin-top: 0;

      @include mq($from: medium) {
        position: absolute;
        bottom: 60px;
      }
    }
  }

  &--post {
    background-color: color-var(bg, 5);
    padding: 0;
  }

  &__post {
    &-image {
      height: 420px;
      float: right;
      width: calc(100% + 115px);
    }
    &-description{
      display: flex;
    }
  }

  &-tease{
    .c-heading{
      margin-top: 40px;
    }
  }
}
