.c-cookie{
    position: fixed;
    display: none;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: color-var(bg,5);
    z-index: 123123123;
    text-align: center;
    padding: 10px 0;
    &__text{
        display: inline-block;
        p{
            margin-bottom: 0;
        }
        a{
            color: darken(color-var(bg, 3), 40%);
            font-weight: 700;

        }
    }

    .cookie-close{
        display: inline-block;
        background-color: color-var(bg, 2);
        font-size: 16px;
        line-height: 22px;
        padding: 10px 15px;
        color: color-var(bg,1);
        text-transform: uppercase;
        letter-spacing: 1.78px;
        font-weight: 700;
        cursor: pointer;

        @include transition(all, linear, 0.2s);

        &:hover{
            background-color: rgba(color-var(bg, 2), 0.8);
        }
    }
}
