.c-hero {
  position: relative;
  &__title,
  &__subtitle {
    color: color-var(font, 3);
    margin-bottom: 0;
  }
  &__content {
    text-align: center;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    height: 100%;
  }
  &--small {
    height: 480px;
  }

  &--normal {
    height: 550px;
  }

  &--big {
    height: 600px;
  }

  &--full {
    height: 710px;
    padding-top: 110px;
    margin-top: -110px;
  }

  &--secondary {
    .c-hero {
      &__content {
        text-align: left;
      }
      &__container {
        justify-content: flex-start;
      }
    }
  }

  &:after {
    @include absolutePseudoContent();
    @include absPosition100();
    pointer-events: none;
  }

  @each $type, $colors in $baseColors {
    $iter: 1;

    @each $color in $colors {
      &.u-color--#{$type}-#{$iter}:after {
        background-color: $color !important;
        opacity: 0.8;
      }

      $iter: $iter + 1;
    }
  }
  &__video {
    position: absolute;
    @include absPosition100();
    pointer-events: none;
    overflow: hidden;

    .c-embed {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;

      iframe {
        position: absolute;
        top: -70px;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
