.c-404{
  text-align: center;
  padding: 70px 0;
  background-color: color-var(func, 4);
  &__heading{
    font-size: 100px;
    color: color-var(font, 4);
    line-height: 0.9;
    @include mq($from: medium){
      font-size: 200px;
    }
    @include mq($from: large){
      font-size: 300px;
    }
  }
  &__text{
    font-size: 20px;
    font-weight: 700;
  }
}
