/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: color-var(bg);
  color: color-var(font);
  font-family: $font-sans-serif;
  font-size: $inuit-global-font-size;
  /* [1] */
  /* 13px */
  line-height: 1.5;
  /* [1] */
  min-height: 100%;
  /* [3] */
  overflow-y: scroll;
  /* [2] */
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body {
  min-height: 100vh;
  overflow: hidden;
}

.editor-block-list__layout {
  background: color-var(bg);
  color: color-var(font);
  font-family: $font-sans-serif;
  font-size: $inuit-global-font-size;
  /* [1] */
  line-height: 1.5;
  overflow-x: hidden;
}

.block-editor-block-list__layout
  .block-editor-default-block-appender
  > .block-editor-default-block-appender__content,
.block-editor-block-list__layout
  > .block-editor-block-list__block
  > .block-editor-block-list__block-edit,
.block-editor-block-list__layout
  > .block-editor-block-list__layout
  > .block-editor-block-list__block
  > .block-editor-block-list__block-edit {
  margin-top: 0;
  margin-bottom: 0;
}
