.c-popup-login{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  z-index: 123123123;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:before{
    content: "";
    background-color: color-var(bg, 9);
    opacity: 0.89;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__form{
    position: relative;
    width: 370px;
    max-width: 100%;
    background-color: color-var(bg, 1);
    z-index: 123123;
    padding: 24px 35px;
    overflow: auto;

    a{
      color: color-var(font, 4);
      font-weight: 700;
    }

    &-bottom{
      width: 370px;
      max-width: 100%;
      background-color: color-var(bg, 13);
      z-index: 123123;
      text-align: center;
      padding: 20px;
      p{
        font-weight: 700;
        margin-bottom: 0;
      }
      a{
        color: color-var(font, 4);
      }

    }
  }

  &__close {
    position: absolute;
    right: 6px;
    top: 0;
    font-size: 21px;
    color: color-var(font, 4);
    opacity: 0.3;
  }

}
