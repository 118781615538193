.c-text-block{
  background-color: color-var(bg,7);
  background-position: right 20% bottom;
  background-repeat: no-repeat;
  background-size: 20%;
  &__content {
    h1, h2, h3, h4, h5, h6 {
      @extend .u-font-color--5;
      @extend .u-text--natural;
    }
    p {
      @extend .u-text--smaller;
      margin-bottom: $inuit-global-spacing-unit-huge;
      @extend .u-font-color--3;
      &:last-of-type{
        margin-bottom: 0;
      }
    }
    ul, ol{
      @extend .u-text--smaller;
      @extend .u-font-color--3;
      li{}
    }
    a{
      @extend .u-text--smaller;
      @extend .u-font-color--5;
    }
  }

  &--seo{
    h1, h2, h3, h4, h5{
      margin-bottom: 0;
    }
    p{
      margin-bottom: 20px;
    }
  }
}

.u-text{}
