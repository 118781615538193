.c-contact {
  background-color: color-var(func,6);
  color: color-var(font,3);
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  padding: 10px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  z-index: 11111111111;

  &__popup{
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    text-align: center;
    background-color: rgba(#000, 0.9);
    z-index: 11111111;
    display: none;
    @include transition();
  }

  &__content{
    padding: 60px 15px;
    overflow: auto;
    height: 100%;
    @include transition();

  }
  &__close{
    position: absolute;
    right: 30px;
    bottom: 30px;
  }

  a {
    color: color-var(font, 5);
  }
}
