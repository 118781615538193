/* ==========================================================================
   #CHECKBOX
   ========================================================================== */

.c-checkbox {
  @include clickableInput();

  &__replacement {
    border-radius: 0;
    @include checkboxTick();
  }
}
