.c-lp-products {
  &__title {
    color: color-var(font,1);
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 48px;
  }
  &__boxes{}
  &__box{
    text-align: center;
    background-color: color-var(bg, 5);
    padding: 35px 28px 24px 28px;
    height: 100%;
    &-image{
      height: 190px;
      width: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 20px;
    }
    &-title{
      color: color-var(font,1);
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
    }
    &-desc{
      color: color-var(font,1);
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 40px;
    }
  }
}
