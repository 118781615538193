.c-widget {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  &__title {
    @include inuit-font-size($inuit-global-font-size);
    font-weight: 400;
    margin-bottom: $inuit-global-spacing-unit-great;
    color: color-var(font, 3);
    text-transform: uppercase;
  }
  &__article {
    margin-bottom: $inuit-global-spacing-unit-great;
    color: color-var(font, 2);
  }

  &_text{
    color: color-var(font, 3);
    @include inuit-font-size($inuit-global-font-size * 0.875);
    a{
      color: color-var(font, 5);
      @include inuit-font-size($inuit-global-font-size * 0.875);
      @extend .o-link;
    }
  }

  &_nav_menu {
    color: color-var(font,3);

    a {
      @include transition();
      @include inuit-font-size($inuit-global-font-size);
      &:hover{
        @include transition();
      }
    }
  }

  &_media_image {
    img{
      width: 133px;
      max-width: 100%;
    }
  }
  &__title{
    @extend .u-font-color--4;
    text-transform: unset;
    font-weight: 700;
  }

  &__popular-posts {
    .c-widget__title {
      margin-bottom: 20px;
    }
    .c-tease:first-of-type{
      padding-top: 0;
    }

  }

  &__search-posts {
    .c-widget__title {
      margin-bottom: 20px;
    }
  }

  &__newsletter{
    background-color: color-var(bg, 3);
    padding: 35px 15px;


    .c-heading{
      font-size: 24px;
      line-height: 36px;
    }
    .c-label--toggleable{
      border-bottom: none;
    }
  }

}
