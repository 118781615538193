.c-category{

  &__item{
    margin-bottom: 24px;
    background-color: color-var(bg, 5);
    &-image{

      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 225px;
    }
    &-title{
      font-size: 24px;
      line-height: 36px;
      color: color-var(font, 1);
    }
    &-desc{
      font-size: 14px;
      line-height: 21px;
      color: color-var(font, 1);
    }
  }

  &__items{
    &--colors{
      .c-category__item {
        &-title{
          font-size: 24px;
          line-height: 36px;
          color: color-var(font, 3);
        }
        &-desc{
          font-size: 14px;
          line-height: 21px;
          color: color-var(font, 3);
        }


        &:nth-of-type(3n+1) {
          background-color: color-var(bg, 10);
        }

        &:nth-of-type(3n+2) {
          background-color: color-var(bg, 11);
        }

        &:nth-of-type(3n+3) {
          background-color: color-var(bg, 3);
        }
      }
    }
  }





}
