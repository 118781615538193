.c-tag {
  display: inline-block;
  color: color-var(font, 3);
  @include inuit-font-size($inuit-font-size-h6, 1.3334em);
  background-color: color-var(bg, 2);
  text-transform: uppercase;
  border-radius: 12px;
  padding: 3px 11px;
  text-decoration: none;

  &--alternative {
    color: color-var(font, 2);
    background: color-var(bg);
    border: 1px solid color-var(border);
    border-radius: 2px;
  }
}
