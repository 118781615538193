/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  background: color-var(bg, 5);
  position: relative;
  z-index: 1000;


  &__wrapper {
    //padding-top: $inuit-global-spacing-unit;
    //padding-bottom: $inuit-global-spacing-unit;
    //min-height: $inuit-global-spacing-unit-massive;
    position: relative;
  }

  &__search{
    vertical-align: sub;
    margin-right: 10px;
  }

  &__container {

    @include mq($from: large){
      text-align: center;
    }

    color: black;

    &--top {
      background-color: color-var(bg, 2);
      padding: 10px 0;

      @include mq($from: large) {
        padding: 20px 0;
      }
    }

    &--bottom {
      background-color: color-var(bg, 1);
      padding: 21px 0;
      border-bottom: 1px solid color-var(border, 8);

      &.js-fixed{
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  &__logo {

    &--main {


      height: 28px;
      @include mq($from: large) {
        height: 38px;
      }
    }
    &--sub {
      height: 25px;
      @include mq($from: large) {
        height: 38px;
      }
    }
    &--lp {
      width: 145px;
    }
  }

  &--landing-page{
    background-color: color-var(bg, 1);
    padding: 15px 0;
  }

  &__mobile-menu {
    padding-bottom: 15px;
    background: color-var(bg, 5);
    display: none;
    position: absolute;
    width: 100%;
    top: 100%;
    height: 100vh;

    &.is-active {
      display: block;
    }

    .c-main-nav{
      flex-direction: column;
      align-items: flex-start;
      .c-main-nav__item:not(:first-of-type){
        margin-left: 0;
      }
    }

    &-trigger {
      display: inline-block;
      cursor: pointer;
      vertical-align: middle;

      .c-header__icon{
        display: flex;
        align-items: center;
        font-weight: 700;
        padding-right: 40px;

        &--search{
          vertical-align: middle;
        }

        &.is-open{
          padding-right: 25px;
        }

        color: color-var(font, 4);
        svg{
          position: absolute;
          right: 15px;
        }
        svg *{
          fill: color-var(font, 4);
        }

      }

      .is-open {
        display: none;
      }

      .is-closed {
        display: flex;
      }

      &.is-active {
        .is-open {
          display: flex;
        }

        .is-closed {
          display: none;
        }
      }
    }
  }
}
