.c-breadcrumbs {
  @include inuit-font-size($inuit-font-size-h5);
  color: color-var(font, 2);
  padding: $inuit-global-spacing-unit-large 0;

  a,
  span {
    display: inline;
  }

  a {
    color: color-var(font);
    margin-right: $inuit-global-spacing-unit-tiny;
  }

  .breadcrumb {
    &_last {
      margin-left: $inuit-global-spacing-unit-tiny;
    }
  }
}
