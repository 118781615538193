.c-archive {
  &-header{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: color-var(bg, 2);
    position: relative;
    z-index: 12;
    padding: 80px 0;
    &:before {
      background-color: #000;
      opacity: 0.4;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
    }
  }
}
