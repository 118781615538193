.c-rodo{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(color-var(bg, 4), 0.7);
    z-index: 123123123;
    //display: flex;
    justify-content: center;
    align-items: center;

    &__text{
        width: 1100px;
        max-width: 85%;
        max-height: 90%;
        padding: 10px;
        //height: 400px;
        background-color: color-var(bg, 1);
        text-align: center;
        position: relative;

    }

    &__content{
        padding: 20px;
        overflow: auto;
        height: 75vh;

        @include mq($from: medium) {
            height: auto;
        }

        p{
            margin-bottom: 0;
        }
    }

    .rodo-close{
        position: absolute;
        right: -20px;
        top: -20px;
        display: inline-block;
        background-color: color-var(bg, 2);
        font-size: 16px;
        line-height: 22px;
        padding: 10px 15px;
        color: color-var(bg,1);
        text-transform: uppercase;
        letter-spacing: 1.78px;
        font-weight: 700;
        cursor: pointer;

        @include transition(all, linear, 0.2s);

        &:hover{
            background-color: rgba(color-var(bg, 2), 0.9);
        }
    }
}
