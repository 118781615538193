.c-lp-table{
  padding-top: 130px;
  &__title{
    margin-top: 100px;
    color: color-var(font,4);
    font-size: 37px;
    line-height: 60px;
  }
  &__desc{
    color: color-var(font,4);
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  &__image{}
  &__text-sections{}
  &__text-section{
    &-name{
      color: color-var(font,4);
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin-bottom: 10px;
      display: block;
    }
    &-desc{}
  }
  &__table{
    padding-left: 32px;
    margin-top: 30px;
    margin-bottom: 20px;
    .o-flex__item{
      padding-left: 0;
    }

    &-heading{
      background-color: color-var(bg, 5);
      font-weight: 700;
      font-size: 14px;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    &-content{
      padding: 10px;
      font-size: 14px;
      border: 1px solid color-var(bg, 5);
      min-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  &__bottom{
    &-text{
      font-size: 14px;
      color: color-var(font, 2);
    }
  }
}
