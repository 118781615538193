/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: color-var(bg, 4);
  padding-top: $inuit-global-spacing-unit-huge;
  padding-bottom: $inuit-global-spacing-unit-large;

  &--more-padding {
    padding-bottom: 140px;

    @include mq($from: medium) {
      padding-bottom: $inuit-global-spacing-unit-massive;
    }
  }

  &__widget-2{
    .menu{
      columns: 2;
    }
  }

  .c-widget__title{
    color: color-var(font, 3) !important;
    text-transform: uppercase;
    font-weight: 400;
  }

  &__bottom{
    margin-top: 30px;
  }

  &--landing-page{
    padding-top: $inuit-global-spacing-unit;
    padding-bottom: $inuit-global-spacing-unit;
  }

  &__logo{
    &--lp {
      width: 145px;
    }
  }


}
